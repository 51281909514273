<template>
  <div
    class="bg-white text-center border-solid border-1 border-gray-400 rounded-lg flex items-center relative h-[7.25rem]"
    :class="{
      'flex-col p-[0.3125rem] py-6': layout === 'standard',
      'px-4 py-3': layout === 'rows',
      'gap-4': layout === 'rows',
      'hover:border-primary click:border-primary hover:shadow-bordered cursor-pointer': !vertical.disabled
    }"
    @click="handleClick"
  >
    <atoms-badge
      v-if="vertical.badgeInfo"
      class="!rounded-md !rounded-t-none m-auto absolute top-0 left-1/2 -translate-x-1/2 !text-[0.5rem]/[0.75rem] flex whitespace-nowrap text-center py-[0.125rem] px-[0.375rem] items-center gap-[0.291875rem]"
      :theme="vertical.badgeTheme || 'destructive'"
      size="sm"
    >
      <IconsStar v-if="vertical.title === 'Car Insurance'" />

      {{ vertical.badgeInfo }}
    </atoms-badge>

    <div
      class="flex items-center justify-center"
      :class="{
        'w-12 h-12': layout === 'rows',
        'w-10 h-10': layout === 'standard'
      }"
    >
      <NuxtImg
        v-if="vertical?.home_iconImg"
        :src="vertical.home_iconImg"
        :alt="((vertical && vertical.home_iconImg) || '').replace(/.(svg|png|jpg|jpeg|webp|gif)$/g, '').replace(/(\\|\/)|icons/g, '')"
        :width="vertical && vertical.homeIconDimensions && vertical.homeIconDimensions.width"
        :height="vertical && vertical.homeIconDimensions && vertical.homeIconDimensions.height"
        class="m-auto"
      />

      <component
        :is="vertical.home_icon"
        v-if="vertical?.home_icon"
        class="m-auto"
      />
    </div>

    <p
      class="font-bold"
      :class="{
        'text-left col-span-1 lg:col-span-2 my-auto text-base': layout === 'rows',
        'text-sm': layout === 'standard',
        'text-blue-50': !!vertical.disabled
      }"
    >
      {{ vertical.title }}
    </p>
  </div>
</template>

<script setup>
defineOptions({
  name: 'MoleculesVerticalCard'
})

const $router = useRouter()

const props = defineProps({
  vertical: {
    type: Object,
    required: true,
    default: () => ({}),
    validator: value => 'title' in value &&
      'icon' in value &&
      'url' in value &&
      ('home_icon' in value || 'home_iconImg' in value)
  },

  layout: {
    type: String,
    default: 'standard',
    validator: value => ['standard', 'rows'].includes(value)
  },

  linkUrl: {
    type: String,
    default: ''
  }
})

const handleClick = () => {
  if (props.vertical.crossApp) {
    window.location.href = props.vertical.url
    return
  }

  $router.push({
    path: props.linkUrl || props.vertical.url
  })
}
</script>
